import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout/Layout"
import { PageTitle } from "../components/PageTitle/PageTitle"

interface ImpressumProps {
  data: {
    cms: {
      page: {
        title: string
        legalPagesFields: {
          content: string
        }
      }
    }
  }
}

const Impressum: React.FC<ImpressumProps> = ({ data }) => {
  return (
    <Layout bordertop={"border-t-2"}>
      <div className="rich-text-2 flex w-10/12 flex-col items-center pb-64 text-headings lg:w-7/12">
        <PageTitle>{data.cms.page.title}</PageTitle>
        <div
          className="rich-text-2 flex w-full flex-col items-start"
          dangerouslySetInnerHTML={{
            __html: data.cms.page.legalPagesFields.content,
          }}
        />
      </div>
    </Layout>
  )
}

export default Impressum
export const query = graphql`
  {
    cms {
      page(id: "/impressum-2", idType: URI) {
        title
        legalPagesFields {
          content
        }
      }
    }
  }
`
